.App {
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 100px;
  background: linear-gradient(to bottom, rgb(0, 0, 46), black);
}

@media (min-width: 1200px) {
  .header-text {
    font-size: 110px;
    margin-top: 15px;
    margin-bottom: 0px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
  }

  .header-text span {
    color: #0e79b2;
    font-style: italic;
  }

  .section-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }

  .section-1-main {
    width: 40%;
  }

  .video-container {
    margin-top: 30px;
    display: flex;
    justify-content: center; /* Center the video horizontally */
    align-items: center; /* Center the video vertically */
    width: 100%; /* Full container width */
  }

  /* Custom styles for the video */
  .custom-video {
    width: 100%; /* Adjust to desired percentage of the container */
    max-width: 800px; /* Optional: maximum width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 100%;
  }

  .video-container-2 {
    margin-top: 20px;
    display: flex;
    justify-content: center; /* Center the video horizontally */
    align-items: center; /* Center the video vertically */
    width: 100%; /* Full container width */
  }

  /* Custom styles for the video */
  .custom-video-2 {
    width: 90%; /* Adjust to desired percentage of the container */
    max-width: 400px; /* Optional: maximum width */
    height: auto; /* Maintain aspect ratio */
  }

  .text-1 {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 50px;
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    margin-left: 30px;
  }

  .section-2 {
    margin-top: 40px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    align-self: center;
  }

  .section-2 h2 {
    font-size: 50px;
    margin-top: 10px;
    margin-bottom: 0px;
    color: #0e79b2;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
  }

  .section-2 a {
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
    line-height: 40px;
  }

  .section-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 80px;
  }

  .section-3 img {
    width: 50px;
    cursor: pointer;
  }

  .section-3 a {
    margin-left: 15px;
    margin-right: 15px;
  }

  .section-4 {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
  }

  .section-4 h2 {
    font-size: 60px;
    font-weight: bold;
    text-decoration: dashed;
    color: white;
    font-style: italic;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  }

  .section-4-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
  }

  .dex-logo {
    width: 60px;
    margin-right: 20px;
  }

  .buy-button {
    background-color: black;
    height: 60px;
    width: 40%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.6s ease;
    color: white;
    text-decoration: none;
    border: 2px solid white;
  }

  .buy-button:hover {
    opacity: 60%;
  }

  .buy-button:active {
    opacity: 40%;
  }

  .playlist-container-main {
    margin-top: 50px;
  }

  .playlist-container {
    position: fixed;
    background-color: rgb(18, 18, 18);
    width: 20%;
    height: 200px;
    z-index: 100;
    top: 30px;
    right: 30px;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .playlist {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-style: italic;
  }

  .playlist-controls {
    border-top: 2px solid white;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgb(0, 0, 0);
  }

  .playlist-controls button {
    background: none;
    border: none;
    outline: none;
  }

  .skip-img {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .skip-img:hover {
    opacity: 80%;
  }

  .skip-img:active {
    opacity: 40%;
  }

  .play-img {
    width: 70px;
    height: 70px;
    cursor: pointer;
  }

  .play-img:hover {
    opacity: 80%;
  }

  .play-img:active {
    opacity: 40%;
  }
}

@media (max-width: 1200px) {
  .header-text {
    font-size: 110px;
    margin-top: 15px;
    margin-bottom: 0px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
  }

  .header-text span {
    color: #0e79b2;
    font-style: italic;
  }

  .section-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }

  .section-1-main {
    width: 40%;
  }

  .video-container {
    margin-top: 30px;
    display: flex;
    justify-content: center; /* Center the video horizontally */
    align-items: center; /* Center the video vertically */
    width: 100%; /* Full container width */
  }

  /* Custom styles for the video */
  .custom-video {
    width: 100%; /* Adjust to desired percentage of the container */
    max-width: 800px; /* Optional: maximum width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 100%;
  }

  .video-container-2 {
    margin-top: 20px;
    display: flex;
    justify-content: center; /* Center the video horizontally */
    align-items: center; /* Center the video vertically */
    width: 100%; /* Full container width */
  }

  /* Custom styles for the video */
  .custom-video-2 {
    width: 90%; /* Adjust to desired percentage of the container */
    max-width: 400px; /* Optional: maximum width */
    height: auto; /* Maintain aspect ratio */
  }

  .text-1 {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 50px;
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    margin-left: 30px;
  }

  .section-2 {
    margin-top: 40px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    align-self: center;
  }

  .section-2 h2 {
    font-size: 50px;
    margin-top: 10px;
    margin-bottom: 0px;
    color: #0e79b2;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
  }

  .section-2 a {
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
    line-height: 40px;
  }

  .section-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 80px;
  }

  .section-3 img {
    width: 50px;
    cursor: pointer;
  }

  .section-3 a {
    margin-left: 15px;
    margin-right: 15px;
  }

  .section-4 {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
  }

  .section-4 h2 {
    font-size: 60px;
    font-weight: bold;
    text-decoration: dashed;
    color: white;
    font-style: italic;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  }

  .section-4-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
  }

  .dex-logo {
    width: 60px;
    margin-right: 20px;
  }

  .buy-button {
    background-color: black;
    height: 60px;
    width: 40%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.6s ease;
    color: white;
    text-decoration: none;
    border: 2px solid white;
  }

  .buy-button:hover {
    opacity: 60%;
  }

  .buy-button:active {
    opacity: 40%;
  }

  .playlist-container {
    position: fixed;
    background-color: rgb(18, 18, 18);
    width: 40%;
    height: 200px;
    z-index: 100;
    top: 60px;
    right: 30px;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .playlist {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-style: italic;
  }

  .playlist-controls {
    border-top: 2px solid white;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgb(0, 0, 0);
  }

  .playlist-controls button {
    background: none;
    border: none;
    outline: none;
  }

  .skip-img {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .skip-img:hover {
    opacity: 80%;
  }

  .skip-img:active {
    opacity: 40%;
  }

  .play-img {
    width: 70px;
    height: 70px;
    cursor: pointer;
  }

  .play-img:hover {
    opacity: 80%;
  }

  .play-img:active {
    opacity: 40%;
  }

  .playlist-container-main {
    margin-top: 50px;
  }
}

@media (max-width: 600px) {
  .header-text {
    font-size: 50px;
    margin-top: 15px;
    margin-bottom: 0px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
  }

  .header-text span {
    color: #0e79b2;
    font-style: italic;
  }

  .section-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
  }

  .section-1-main {
    width: 80%;
  }

  .video-container {
    margin-top: 30px;
    display: flex;
    justify-content: center; /* Center the video horizontally */
    align-items: center; /* Center the video vertically */
    width: 100%; /* Full container width */
  }

  /* Custom styles for the video */
  .custom-video {
    width: 100%; /* Adjust to desired percentage of the container */
    max-width: 1000px; /* Optional: maximum width */
    height: 100%; /* Maintain aspect ratio */
    border-radius: 100%;
  }

  .video-container-2 {
    margin-top: 20px;
    display: flex;
    justify-content: center; /* Center the video horizontally */
    align-items: center; /* Center the video vertically */
    width: 100%; /* Full container width */
  }

  /* Custom styles for the video */
  .custom-video-2 {
    width: 90%; /* Adjust to desired percentage of the container */
    max-width: 300px; /* Optional: maximum width */
    height: auto; /* Maintain aspect ratio */
  }

  .text-1 {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
  }

  .section-2 {
    margin-top: 40px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    align-self: center;
  }

  .section-2 h2 {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 0px;
    color: #0e79b2;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
  }

  .section-2 a {
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    line-height: 40px;
  }

  .section-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 80px;
  }

  .section-3 img {
    width: 50px;
    cursor: pointer;
  }

  .section-3 a {
    margin-left: 15px;
    margin-right: 15px;
  }

  .section-4 {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
  }

  .section-4 h2 {
    font-size: 40px;
    font-weight: bold;
    text-decoration: dashed;
    color: white;
    font-style: italic;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  }

  .section-4-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    width: 100%;
  }

  .dex-logo {
    width: 40px;
    margin-right: 20px;
  }

  .buy-button {
    background-color: black;
    height: 60px;
    width: 80%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 40px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.6s ease;
    color: white;
    text-decoration: none;
    border: 2px solid white;
    font-size: 18px;
  }

  .buy-button:hover {
    opacity: 60%;
  }

  .buy-button:active {
    opacity: 40%;
  }

  .playlist-container-main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    flex-direction: column;
    margin-top: 10px;
  }

  .playlist-container {
    position: relative;
    background-color: rgb(18, 18, 18);
    width: 90%;
    height: 150px;
    z-index: 100;
    top: 10px;
    right: 0px;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: center;
  }

  .playlist {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-style: italic;
    font-size: 10px;
  }

  .playlist-controls {
    border-top: 2px solid white;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgb(0, 0, 0);
  }

  .playlist-controls button {
    background: none;
    border: none;
    outline: none;
  }

  .skip-img {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .skip-img:hover {
    opacity: 80%;
  }

  .skip-img:active {
    opacity: 40%;
  }

  .play-img {
    width: 70px;
    height: 70px;
    cursor: pointer;
  }

  .play-img:hover {
    opacity: 80%;
  }

  .play-img:active {
    opacity: 40%;
  }

  .buynow-text {
    font-size: 20px;
  }
}
